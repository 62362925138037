<template>
  <v-layout wrap>
    <v-flex xs12>
      <v-btn @click="onAddAnswer">
        {{$t('dtouch.survey.addAnswer')}}
      </v-btn>
    </v-flex>
    <v-flex xs12
      v-for="(ans, index) in item.values"
      :key="ans.id"
    >
      <translate
        v-model="ans.answer"
        :languages="config.Languages"
        :label="$t('dtouch.survey.answer') + ' ' + (index + 1)"
        @input="handleChangeIsModified"
        style="margin: 0;"
      />
    </v-flex>
  </v-layout>
</template>
<script>
  import Translate from '@/components/Translate'
  export default {
    components: {
      Translate
    },
    props: {
      item: {
        type: Object,
        required: true
      },
      onAddAnswer: {
        type: Function,
        required: true
      },
      onChangeIsModified: {
        type: Function,
        required: true
      },
      config: {
        type: Object,
        required: true,
      },
    },
    methods: {
      handleChangeIsModified () {
        this.onChangeIsModified()
      },
    },
  }
</script>
